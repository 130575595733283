import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PsyIcon } from "../assets/psy-icon.svg";
import { ReactComponent as NeuropsyIcon } from "../assets/neuropsy-icon.svg";
import { ReactComponent as KineIcon } from "../assets/kine-icon.svg";
import { ReactComponent as LogopedeIcon } from "../assets/logopede-icon.svg";
import { ReactComponent as DoulaIcon } from "../assets/doula-icon.svg";
import { ReactComponent as ReflexoIcon } from "../assets/reflexology-icon.svg";
import { HashLink } from 'react-router-hash-link';


const ProfessionCards = ({
  title,
  strongTitle,
  highlightedTitle,
  description,
}) => {
  const navigate = useNavigate();
  

  const cardsInfosTab = [
    {
      number : "one",
      icon: (
        <HashLink to="/lequipe#t1" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
          <LogopedeIcon
          className="card-icon"
          title="Icônes imbriquées d'un enfant et d'un parent."
          alt="Icônes imbriquées d'un enfant et d'un parent."
        />
        </HashLink>
      ),
      title: "Logopédie",
      description:
        "Langage oral, langage écrit, cognition numérique, déglutition/dysphagie, handicap, troubles neurologiques",
    },
    {
      number : "two",
      icon: (
        <HashLink to="/lequipe#t5" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
          <KineIcon
          className="card-icon"
          title="Icône d'une personne en mouvement."
          alt="Icône d'une personne en mouvement."
        />
        </HashLink>
      ),
      title: "Kinésithérapie",
      description:
        "Travail proprioceptif pour améliorer la prise de conscience du schéma corporel et la fluidité du mouvement dans un but de rééquilibration posturale. (accompagnement moteur de la naissance à la marche, troubles de l’équilibre chez l’enfant et l’adulte, torticolis et déformations crâniennes du tout-petit, tensions neuro-musculaires, travail postural)",
    },
    {
      number : "three",
      icon: (
       //Hashlink deleted after Jade left
          <PsyIcon
          className="card-icon"
          title="Icône d'un arbre et la lettre psy grecque."
          alt="Icône d'un arbre et la lettre psy grecque."
        />
      ),
      title: "Psychologie",
      description: "Suivi individuel et familial, guidance parentale, accompagnement des difficultés d’apprentissage et de comportement chez le jeune, suivi thérapeutique de l’enfant, l’adolescent et l’adulte.",
    },
    {
      number : "four",
      icon: (
        <HashLink to="/lequipe#t3" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
          <NeuropsyIcon
          className="card-icon"
          title="Icône d'un cerveau et d'un labyrinthe."
          alt="Icône d'un cerveau et d'un labyrinthe."
        />
        </HashLink>
      ),
      title: "Neuropsychologie",
      description:
        "Réalisation de bilans neuro-psychologiques, attentionnels et intellectuels prise en charge des troubles attentionnels et exécutifs, prise en charge des maladies neuro-dégénératives et neuro-développementales",
    },
    {
      number : "five",
      icon: (
        <HashLink to="/lequipe#t1" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
          <DoulaIcon
          className="card-icon"
          title="Icône d'une femme enceinte."
          alt="Icône d'une femme enceinte."
        />
        </HashLink>
      ),
      title: "Doula",
      description:
        "Accompagnement à la naissance et à la parentalité",
    },
    {
      number : "six",
      icon: (
        <HashLink to="/lequipe#t5" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
          <ReflexoIcon
          className="card-icon"
          title="Icône de deux pieds."
          alt="Icône de deux pieds."
        />
        </HashLink>
      ),
      title: "Réflexologie plantaire",
      description:
        "Le massage plantaire consiste à exercer des pressions à divers endroits du pied pour produire des effets dans les parties du corps correspondantes et rechercher les causes d'un déséquilibre. ",
    },
  ];

  return (
    <div className="profession-cards-wrapper">
      <div className="profession-cards-container">
        <h1 className="profession-cards-title">
          {title} {strongTitle}
          <span dangerouslySetInnerHTML={{ __html: highlightedTitle }}></span>
        </h1>
        {description}
        <div className="cards-wrapper">
          {cardsInfosTab.filter(profession => profession.number === "one").map((el, i) => (
            <div className="card-left" key={i}>   
                <div className="card-icon">{el.icon}</div>
                <div className="card-description-left">
                <HashLink to="/lequipe#t1" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                  <h3>{el.title}</h3>
                  <p>{el.description}</p>
                </HashLink>
                </div>
              {/*<p
                className="card-description"
                dangerouslySetInnerHTML={{ __html: el.description }}
              ></p>*/}
            </div>  
          ))}
          {cardsInfosTab.filter(profession => profession.number === "two").map((el, i) => (
            <div className="card-right" key={i}>                   
                <div className="card-description-right">
                  <HashLink to="/lequipe#t5" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                    <h3>{el.title}</h3>
                    <p>{el.description}</p>
                  </HashLink>
                </div>
                <div className="card-icon">{el.icon}</div>
            </div>  
          ))}
          {cardsInfosTab.filter(profession => profession.number === "three").map((el, i) => (
            <div className="card-left" key={i}>   
              <div className="card-icon">{el.icon}</div>
              <div className="card-description-left">
                <HashLink to="/lequipe#t2" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                <h3>{el.title}</h3>
                <p>{el.description}</p></HashLink>
              </div>
            </div>  
          ))}
          {cardsInfosTab.filter(profession => profession.number === "four").map((el, i) => (
            <div className="card-right" key={i}>   
                <div className="card-description-right">
                  <HashLink to="/lequipe#t3" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                    <h3>{el.title}</h3>
                    <p>{el.description}</p>
                  </HashLink>
                </div>
                <div className="card-icon">{el.icon}</div>
            </div>  
          ))}
          {cardsInfosTab.filter(profession => profession.number === "five").map((el, i) => (
            <div className="card-left" key={i}>   
                <div className="card-icon">{el.icon}</div>
                <div className="card-description-left">
                  <HashLink to="/lequipe#t1" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                    <h3>{el.title}</h3>
                    <p>{el.description}</p>
                  </HashLink>
                </div>
            </div>  
          ))}
          {cardsInfosTab.filter(profession => profession.number === "six").map((el, i) => (
            <div className="card-right" key={i}>   
                <div className="card-description-right">
                  <HashLink to="/lequipe#t6" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                    <h3>{el.title}</h3>
                    <p>{el.description}</p>
                  </HashLink>
                </div>
                <p className="card-icon">{el.icon}</p>
            </div>  
          ))}  
        </div>
      </div>
    </div>
  );
};

export default ProfessionCards;

import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Teamheader } from "../assets/equipe.svg";
import { ReactComponent as Teamheader2 } from "../assets/equipe2.svg";
import { ReactComponent as TeamheaderResponsive } from "../assets/equipe-responsive.svg";
import { ReactComponent as TeamheaderResponsive2 } from "../assets/equipe-responsive2.svg";
import '../styles/team.css'
import MarionAbou from '../assets/MarionAbou-min.jpg'
import ElenaPaccagnella from '../assets/Elena-min.jpg'
import NinaLeruth from '../assets/Leruth-min.jpg'
import AuroreConegero from '../assets/Aurore-min.jpg'
import NaimClerin from '../assets/Naim.jpeg'
import Francelina from '../assets/Cavadias-min.jpg'
import AuroraMoyano from '../assets/moyano.jpeg'

const Equipe = ({
  name,
  profession,
  description,
 
}) => {
    const navigate = useNavigate();
    const teamInfos = [
      {
        id: "one",
        name : "Marion Abou",
        photo: MarionAbou,
        profession: "Logopède",
        description:
        `J’ai travaillé pendant 10 ans en contexte scolaire dans l’accompagnement d’enfants présentant des difficultés d’apprentissage. Dans ma pratique j’ai donc travaillé en étroite collaboration avec les équipes enseignantes ainsi que les professionnels gravitant autour des jeunes. 
Je me suis par la suite spécialisé en cognition numérique (anciennement dyscalculie) et je me forme actuellement dans l’accompagnement des patients autistes en tant que logopède. 
J’accueille aujourd’hui des patients présentant plusieurs difficultés
Logopédie : langage oral, langage écrit, cognition numérique, déglutition/dysphagie, handicap, troubles neurologiques.

Je suis aussi Doula, j’accompagne des parents et futurs parents dans le processus d’évolution  de la naissance et à la parentalité. Dans ce cadre, je reçois des femmes enceintes qui souhaitent recevoir un soutien physique et émotionnel pendant et après l’accouchement.

Je suis conventionnée, je pratique donc les tarifs demandés par l’Inami.
        `,
      },
      {
        id: "two",
        name : "Aurore Conegero",
        photo: AuroreConegero,
        profession: "Psychologue",
        description:
        `Psychologue clinicienne et du développement, j’ai travaillé pendant 10 ans dans l’enseignement auprès d’enfants à besoins spécifiques. Par la suite, j’ai aussi orienté ma pratique dans l’accompagnement des adultes et de leurs difficultés. Je m’intéresse par ailleurs aux thématiques liées au monde du travail et des institutions. 
Je propose des consultations pour les enfants à partir de 4 ans, les adolescents et les adultes. J’accueille aussi des familles dans des projets de guidance parentale faisant suite à des difficultés familiales.

J’utilise principalement les outils et les concepts issus des thérapies systémiques et psychanalytiques. J’accompagne mes patients, à leur rythme, dans la recherche de sens de leurs symptômes afin de viser un mieux-être personnel. 

• Enfants : troubles du comportement, troubles du spectre de l’autisme, difficultés scolaires, gestion des émotions, angoisses.

• Adultes : anxiété, dépression, troubles psycho-somatiques, difficultés relationnelles.

Tarif : 60 euros la séance (entre 45min et 60min)

Je suis conventionnée en tant que psychologue de première ligne pour le réseau BruStars (patients jusqu’à 23 ans) et Brumenta (patients adultes) permettant des adaptations tarifaires.
`,
      },
      {
        id: "three",
        name : "Nina Leruth",
        photo: NinaLeruth,
        profession: "Neuropsychologue",
        description:
          `La neuropsychologie est l’étude du lien entre ce qu’il se passe dans notre cerveau et nos comportements, nos émotions et nos pensées. 
De manière générale, la neuropsychologie va s’intéresser aux fonctions cognitives qui sont la mémoire, l’attention, la perception de l’espace, le langage et la perception sociale.

Je suis psychologue clinicienne spécialisée en neuropsychologie, diplômée de l’ULB. J’ai effectué deux stages en hôpital. J’ai en premier lieu effectué un stage auprès d’enfants faisant face à des difficultés d’apprentissage et porteurs de troubles neurodéveloppementaux. J’ai réalisé mon deuxième stage dans un service de neurologie, recevant des personnes adultes et âgées, principalement dans le but d’identifier la présence de pathologies neurodégénératives et d’épuisement professionnel.

Je reçois aujourd’hui enfants et adultes pour réaliser des bilans neuropsychologiques. Ceux-ci ont pour but d’identifier les forces et les faiblesses de chacun, dans des domaines comme la mémoire, l’attention, la perception de l’espace et au niveau intellectuel. Pour mettre en évidence les difficultés, nous commençons par nous rencontrer pour retracer ensemble l’historique du patient, et identifier les problèmes dans la vie quotidienne et au travail ou à l’école. Je propose ensuite une série de tests et/ou de questionnaires pour cibler les ressources et les faiblesses dans différents domaines. En fonction des difficultés mises en évidence par le bilan, je peux proposer un suivi pour prendre en charge celles-ci et travailler ensemble en s’appuyant sur les forces de chacun.

J’accueille chaque patient dans un esprit de bienveillance.`,
      },
      {
        id: "four",
        name : "Elena Paccagnella",
        photo: ElenaPaccagnella,
        profession: "Logopède",
        description:
        `Logopède passionnée par le développement du langage, mon objectif est de concilier ma solide base théorique, acquise lors de mon master en logopédie, avec une approche clinique empreinte de bienveillance envers chaque patient. 

Guidée par la conviction que le développement du langage est un processus complexe, je m'engage à créer des interventions personnalisées, adaptées aux besoins uniques de chaque enfant que j’accompagne. Je favorise la collaboration avec les familles et/ou les enseignants, reconnaissant ainsi l'importance de l'environnement dans le développement de l’enfant. 

Mon objectif consiste à favoriser l'autonomie du patient et à cultiver sa confiance intrinsèque dans le processus d'apprentissage… Le tout avec le cœur ! 

J’accompagne les enfants d’âge scolaire et préscolaire :
• Un retard de parole/langage, un trouble de l'articulation ou du langage oral, une absence ou peu de langage à la petite enfance
• Des difficultés de langage écrit, dyslexie, dysorthographie
• Les troubles myofonctionnels

Je suis conventionnée, je pratique donc les tarifs demandés par l’Inami.
`,
      },
      {
        id: "five",
        name : "Naïm Clérin",
        photo: NaimClerin,
        profession: "Kinésithérapeute",
        description:
          `Jeune kiné généraliste, j'ai toujours été  intéressé par la compréhension du corps et passionné par l'humain. Ce que je veux dire par là c'est que chacun a ses pensées, ses difficultés, ses habitudes, ses envies, ses motivations. Et prendre en compte ce contexte personnel fait partie intégrante de mes traitements kiné, car l'explorer permet d'une part, plus précisions dans les diagnostics et d'autres part, un traitement plus adapté.

Concernant le prix, je suis conventionné parce que c'est important pour moi que les soins soient accessibles à tous.  
          
Mes spécialités sont :
  • La prise en charge de l'articulation temporo mandibulaire (mâchoire) 
  • La prise en charge des douleurs de dos
          
Je pratique aussi :
  • La kiné respiratoire pour tout âge 
  • Le drainage lymphatique
  • La kiné post chirurgicale
  • La rééducation fonctionnelle 
  • Le reconditionnement à l'effort
          
Bienvenue !`,
      },
      {
        id: "six",
        name : "Francelina Cavadias",
        photo: Francelina,
        profession: "Réflexologue et psychologue du travail",
        description:
          `La réflexologie causale est une pratique qui est fondée sur les principes de l'ostéopathie et qui a été développée par An De Mulder. La réflexologie causale s'intéresse aux causes d'un déséquilibre. 
Le massage consiste à exercer des pressions à divers endroits du pied pour produire des effets dans les parties du corps correspondantes dans le but de retrouver l'homéostasie et un meilleur équilibre.  
          
Je vous propose des soins pour les adultes (60€), les enfants et les bébés (40€).
La durée des séances varie entre 1h et 1h30.
Veuillez noter qu’il existe dans cette pratique quelques contre indications, n’hésitez pas à nous consulter pour plus de détails : 
  • Fièvre 
  • Grossesse 
  • Maladies graves (cancer, problèmes cardiaques, etc.)
  • Maladies auto-immunes (Hashimoto, Sclérose en plaques, etc.)`,
      },
      {
        id: "seven",
        name : "Aurore Moyano",
        photo: AuroraMoyano,
        profession: "Psychologue clinicienne et éducatrice spécialisée",
        description:
          `Je suis psychologue clinicienne et éducatrice spécialisée. 
Passionnée par l’évolution, le développement et le grandir ensemble, j’ai toujours  travaillé dans le secteur social.  

Ma vocation : travailler avec et pour des enfants et adolescents ayant des  difficultés de comportement comme élément majeur ou avec d’autres spécificités associées, ainsi qu’avec et pour leur entourage. 

Je propose un accompagnement thérapeutique mais aussi éducatif et de guidance parentale. En cabinet mais également dans les milieux de vie (outreaching: maison, école, etc.)

Tarifs : En cabinet 60€ ( séance entre 45 et 60 minutes). 

En outreaching : prix forfaitaire variable en fonction des spécificités de la situation. 
Je suis également conventionnée en tant que psychologue de première ligne pour le réseau BruStars (patients jusqu’à 23 ans) et Brumenta (patients adultes) permettant des séances à tarif réduits.`,
      },
    ];
    return (
      <div className="team-page"> 
        <div className="team-header">
          <Teamheader2/>
        </div>
        <div className="team-header-responsive">
          <TeamheaderResponsive2/>
        </div>
        <div className="team-container">
          {teamInfos.filter(member => member.id === "one").map(el => (
            <div id="t1" className="team-member">
              <div className="team-left-box">
                  <img src={el.photo} className="team-img-abou" alt="Marion Abou"/>
                  <div className="team-content-abou">
                    <p className="team-name">{el.name}</p>
                    <p className="team-profession">{el.profession}</p>
                  </div>
              </div>
              <p className="team-text">{el.description}</p>  
            </div>
                
          ))}
          {teamInfos.filter(member => member.id === "two").map(el => (
            <div id="t2" className="team-member">
              <div className="team-left-box">
                <img src={el.photo} className="team-img-conegero" alt="Aurore conegero"/>
                <div className="team-content-conegero">
                  <p className="team-name">{el.name}</p>
                  <p className="team-profession">{el.profession}</p>
                </div>
              </div>
              <div>
                <p className="team-text">{el.description}</p>                      
              </div>
            </div>
          ))}
          {teamInfos.filter(member => member.id === "three").map(el => (
            <div id="t3" className="team-member">
              <div className="team-left-box">
                  <img src={el.photo} className="team-img-leruth" alt="Nina Leruth"/>
                  <div className="team-content-leruth">
                    <p className="team-name">{el.name}</p>
                    <p className="team-profession">{el.profession}</p>
                  </div>
              </div>
              <p className="team-text">{el.description}</p>
            </div>
          ))}
          {teamInfos.filter(member => member.id === "four").map(el => (
            <div id="t4" className="team-member">
              <div className="team-left-box">
                  <img src={el.photo} className="team-img-paccagnella" alt="elena paccagnella"/>
                  <div className="team-content-paccagnella">
                    <p className="team-name">{el.name}</p>
                    <p className="team-profession">{el.profession}</p>
                  </div>
              </div>
              <div>
                    <p className="team-text">{el.description}</p>                      
                  <div className="team-rdv-paccagnella">         
                      <a href=" https://rosa.be/fr/elena-paccagnella" target="_blank" className="arrow-paccagnella"></a>
                      <a className="team-rdv-link-paccagnella" href="https://rosa.be/fr/elena-paccagnella" target="_blank"> Prendre rendez-vous directement avec moi</a>
                  </div>
              </div>
            </div>
          ))}
          {teamInfos.filter(member => member.id === "five").map(el => (
            <div id="t5" className="team-member">
              <div className="team-left-box">
                  <img src={el.photo} className="team-img-clerin" alt="Naïm Clérin"/>
                  <div className="team-content-clerin">
                    <p className="team-name">{el.name}</p>
                    <p className="team-profession">{el.profession}</p>
                  </div>
              </div>
              <div>
                    <p className="team-text">{el.description}</p>                      
                  <div className="team-rdv-clerin">         
                      <a href=" https://rosa.be/fr/naim-clerin" target="_blank" className="arrow-clerin"></a>
                      <a className="team-rdv-link-clerin" href="https://rosa.be/fr/naim-clerin" target="_blank"> Prendre rendez-vous directement avec moi</a>
                  </div>
              </div>
            </div>
          ))}
          {teamInfos.filter(member => member.id === "six").map(el => (
            <div id="t6" className="team-member">
              <div className="team-left-box">
                  <img src={el.photo} className="team-img-francelina" alt="Francelina Cavadias"/>
                  <div className="team-content-francelina">
                    <p className="team-name">{el.name}</p>
                    <p className="team-profession">{el.profession}</p>
                  </div>
              </div>
              <div className="cavadias-text">
                <p><h3>Réflexologie</h3><br></br>{el.description}</p>
                <p><h3>Psychologie du travail</h3><br></br>
                  En tant que psychologue du travail et des organisations/conseiller en prévention aspects psychosociaux, 
                  j'ai constaté que la souffrance au travail se manifeste de multiples façons. Lorsque nous en devenons la cible, 
                  elle affecte notre santé mentale, émotionnelle et/ou physique. 
                  <br></br><br></br>Bien que le travail puisse être une source d'épanouissement et de satisfaction, il peut aussi devenir une source de stress 
                  et de pression qui engendre des insatisfactions, des frustrations et/ou de la souffrance. Un déséquilibre peut de façon insidieuse 
                  s’installer et mener les travailleurs à une forme de fatigue chronique ou d’épuisement pouvant mener au Burn Out. 
                  <br></br><br></br>La souffrance au travail peut également découler de situations où les conflits interpersonnels ou les 
                  comportements transgressifs impactent l'atmosphère de travail et les relations interpersonnelles. 
                  Les comportements transgressifs se rapportent au harcèlement moral ou sexuel, à de la violence et/ou de la discrimination.  
                  <br></br>Les conséquences psychiques et physiques peuvent se manifester de différentes façons : Isolement, dévalorisation, perte de confiance en soi, maux de tête, des troubles intestinaux, des douleurs musculaires, etc. 
                  Sans oublier l’impact que ces situations peuvent générer sur la vie privée.
                  <br></br><br></br>Si vous rencontrez des difficultés liées à votre travail, contactez moi. 
                  Je vous propose une écoute active, un soutien et nous recherchons ensemble les pistes de solutions propres à votre situation. 
                </p>
              </div>
            </div>
          ))}
          {teamInfos.filter(member => member.id === "seven").map(el => (
            <div id="t7" className="team-member">
              <div className="team-left-box">
                  <img src={el.photo} className="team-img-conegero" alt="Aurore Moyano"/>
                  <div className="team-content-conegero">
                    <p className="team-name">{el.name}</p>
                    <p className="team-profession">{el.profession}</p>
                  </div>
              </div>
              <div>
                <p className="team-text">{el.description}</p>                      
                  {/* <div className="team-rdv-moyano">         
                      <a href=" https://rosa.be/fr/aurore-moyano" target="_blank" className="arrow-moyano"></a>
                      <a className="team-rdv-link-moyano" href="https://rosa.be/fr/aurore-moyano" target="_blank"> Prendre rendez-vous directement avec moi</a>
                  </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
};

export default Equipe;

import React, { useState } from "react";
import ArticleImage1 from "../assets/MarionAbou.png";
import ArticleImage2 from "../assets/MarionAbou.png";
import ArticleImage3 from "../assets/MarionAbou.png";
import { Link, useNavigate } from "react-router-dom";
import InvitationRecto from '../assets/Invitation recto.png'
import InvitationVerso from '../assets/Invitation verso.png'


const Article1 = () => {
    const navigate = useNavigate();
    
  return (

    <div className="actualites-main-container">
        <h3 className="actualites-page-title"> Le CASCO vous accueille depuis octobre 2022, nous sommes ravies de vous présenter 
            notre nouveau centre en espérant que vous vous y sentirez aussi bien que nous !
        </h3>
        <div className="actualites-posts-wrapper">
            <img src={InvitationRecto}
                  title=""
                  alt=""
                  className="actualites-invit"
                />
            <div className="actualites-invit-description">
                <p>
                  Nous vous invitons le 31 mars pour l’inauguration du centre au 6 avenue Ilya Prigogine Uccle. 
                  <br/> <br/>
                  Ce sera l'occasion de nous retrouver pour fêter ce nouveau départ !
                </p>    
            </div>
        </div>
        <div className="actualites-posts-wrapper2">
            <h1>
                N'oubliez pas de confirmer votre présence !
            </h1> 
            <div> 
                <img src={InvitationVerso}
                    title=""
                    alt=""
                    className="actualites-invit2"
                />
            </div>
        </div>
        </div>
  );
};

export default Article1;

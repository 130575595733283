import Contact from '../../assets/Contact-min.jpg';
import Meeting from '../../assets/two-kids-min.jpg';
import Analysis from '../../assets/analysis2.jpg';
import Suivi from '../../assets/suivi-min.jpg';
import { ReactComponent as PhoneIcon } from "../../assets/phone-icon.svg";
import { ReactComponent as MailIcon } from "../../assets/mail-icon.svg";



const DataSlider = [
  {
    id: "1",
    image: Contact,
    title: "1 - Premier contact",
    description1: "Pour ",
    descriptionbold: "entendre",
    description2: " votre demande et fixer votre premier rendez-vous",
    icon1: (
      <PhoneIcon
        className="contact-icon"
      />
    ), 
    icon2: (
      <MailIcon
        className="contact-icon"
      />
    ), 
    alt: "alt",
    bouton: "Prise de contact"
  },
  {
    id: "2",
    image: Meeting,
    title: "2 - Premier entretien en binôme",
    description1: "Pour ",
    descriptionbold: "comprendre",
    description2: " votre demande et votre parcours",
    icon1: (
      <PhoneIcon
        className="contact-icon"
      />
    ), 
    icon2: (
      <MailIcon
        className="contact-icon"
      />
    ),
    alt: "alt",
    bouton: "Premier entretien"
  },
  {
    id: "3",
    image: Analysis,
    title: "3 - Analyse",
    description1: "Pour ",
    descriptionbold: "discuter",
    description2: " ensemble de l’accompagnement le plus adapté",
    icon1: (
      <PhoneIcon
        className="contact-icon"
      />
    ), 
    icon2: (
      <MailIcon
        className="contact-icon"
      />
    ),
    alt: "alt",
    bouton: "Analyse"
  },
  {
    id: "4",
    image: Suivi,
    title: "4 - Suivi thérapeutique",
    description1: "Pour vous ",
    descriptionbold: "accompagner",
    description2: " dans votre démarche de soin",
    icon1: (
      <PhoneIcon
        className="contact-icon"
      />
    ), 
    icon2: (
      <MailIcon
        className="contact-icon"
      />
    ),
    alt: "alt",
    bouton: "Suivi thérapeutique"
  },
];

export default DataSlider;
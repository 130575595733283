import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProfessionCards from "../components/ProfessionCards"
import { ReactComponent as HomeIcons } from "../assets/home-icons.svg";
import imageHome from '../assets/home-page-image3.svg'
import backgroundHome from '../assets/background-home.svg'
import imageCasco from '../assets/Casco-devanture.JPG'
import imageCasco2 from '../assets/AbouConegero.jpg'
import MapWrapper from "../utils/services/MapWrapper";
import { Button } from "@mui/material";
import Slider from "../components/Slider";
import DataSlider from "../utils/services/DataSlider";
import '../styles/philosophie.css'

const Philosophie = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [notShowed, setNotShowed] = useState(false);
  const readLocalStorage = () => {
    const localStorageData = window.localStorage.getItem("refuseCookies");
    if (localStorageData) {
      setNotShowed(true);
    }
  };
  useEffect(() => {
    readLocalStorage();
  }, []);
  return (
    <div className="philosophie-page-container">
      <div className="philosophie-CASCO-container">
        <div className="philosophie-CASCO-title">
          Le CASCO
        </div>
        <div className="philosophie-CASCO">
          <div className="philosophie-img-text">
            <div className="philosophie-CASCO-description">
            <p>
              Un CASCO est désigné comme un bâtiment à l’état brut, autrement dit un gros œuvre fermé : tout est encore à construire.
            </p>
            <p>
              <br/> Ce 
              <span> 
                {" "} processus évolutif {" "} 
              </span>
              de construction s’inscrit dans notre démarche professionnelle où chacun devient acteur du processus de soin. 
              <br/> La collaboration tant au sein de notre équipe qu’avec des patients que nous accueillons est donc essentielle à tout accompagnement.
            </p>

            </div>
            <div className="philosophie-CASCO-image">
            <img src={imageCasco}
              style={{borderRadius: '8px'}}
              title="centre CASCO devanture"
              alt="centre CASCO devanture"
              className="philo-img"
            />
            </div>
          </div>
          <div className="philosophie-CASCO-span">  
              <p>Nous sommes donc un centre paramédical pluridisciplinaire qui offre à chaque patient 
                <span>
                  {" "} un accompagnement adapté à ses besoins. {" "}
                </span>
              </p>
          </div>
        </div>
      </div>
      <hr/>
      <div className="philosophie-CASCO-container">
        <div className="philosophie-CASCO-title">
          Le centre, notre philosophie
        </div>
        
        <div className="philosophie-CASCO">
          <p>
            Issu de notre expérience passée dans l’accompagnement de jeunes en milieu scolaire, en créant ce centre nous souhaitions poursuivre notre <b>démarche de 
            collaboration</b> avec tous partenaires extérieurs impliqués dans un processus de soin.
          </p>
          <div className="philosophie-img-text">
            <div className="philosophie-CASCO-image">
            <img src={imageCasco2}
              style={{borderRadius: '8px'}}
              title="centre CASCO devanture"
              alt="centre CASCO devanture"
              className="philo-img"
            />
            </div>
            <div className="philosophie-CASCO-description">
              <p>En effet, l’accompagnement clinique par chaque membre de l’équipe tend vers une volonté d’appréhender tout individu dans sa 
                <b> globalité individuelle et son système.</b> <br></br> 
                Tout accompagnement au sein de notre centre fait donc l’objet d’une réflexion <b>pluridisciplinaire </b>régulière afin de proposer 
                le dispositif le plus adapté à vos besoins. Ceci a aussi pour but de re-questionner notre pratique tout au long de votre parcours de soin. 
                Nous espérons que vous vous sentirez accueillis dans ce lieu que nous avons souhaité inclusif et collectif.
            </p>
            </div>
          </div>
        </div>
      </div>
      <div className="philosophie-CASCO-container">
        <div className="philosophie-CASCO-title">
          Collaboration 
            <h1>
              Pistes thérapeutiques individualisées et réflexions collectives
            </h1>
        </div>
        <div className="philosophie-CASCO">
          <div className="philosophie-CASCO-split2">
            <p>
            Notre pratique professionnelle s’inscrit dans un travail de collaboration. 
            Ainsi nous collaborons avec 
              <span> 
                  {" "} différents intervenants extérieurs {" "} 
              </span>
            tels que les écoles, les CPMS, les médecins, 
            les familles et toutes autres personnes impliquées dans l’accompagnement du patient. 
            </p>
            
          </div>
          <div className="philosophie-CASCO-split2">
            <p>
            Nous échangeons régulièrement lors de 
              <span> 
                  {" "} réunions interdisciplinaires {" "} 
              </span>
             afin que chaque situation 
            puisse être réfléchie à la lumière de chacun de nos thérapeutes. 
            Cela nous permet de questionner notre pratique et de proposer au cours du suivi thérapeutique de nouvelles pistes de réflexion.
            </p>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Philosophie;

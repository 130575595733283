import React, { useState } from "react";
import Post from "./Posts";
import ArticleImage1 from "../assets/Invitation recto.png";
import ArticleImage2 from "../assets/apero_casco.png";
import ArticleImage3 from "../assets/apero_casco_2.png";
import { Link, useNavigate } from "react-router-dom";
import Article1 from "./Article1"
import InvitationRecto from '../assets/Invitation recto.png'
import InvitationVerso from '../assets/Invitation verso.png'
import { ReactComponent as ArrowIcon } from "../assets/arrow-invit.svg";


const Actualites = () => {
    const navigate = useNavigate();
    const ArticlesTab = [
        {
            id: "2",
            image: ArticleImage2,
            alt: "Invitation soirée Casco",
            title: "Nous vous convions pour un moment convivial le vendredi 6 octobre",
            description: 
                "Mis à jour le 11/09/2023",
            author: "Apéro du Casco",
            date: "06/10/2023",
        },
        {
            id: "1",
            image: ArticleImage1,
            alt: "invitation à l'inauguration",
            title: "Notre centre pluridisciplinaire ouvre ses portes ce 31 mars.",
            description: 
                    "Mis à jour le 25/01/2023",
            author: "Inauguration Casco",
            date: "31/03/2023",
        },
        {
            id: "3",
            image: ArticleImage3,
            alt: "invitation à l'inauguration",
            title: "Venez pour notre apéro du printemps ce 31 mai.",
            description: 
                    "Mis à jour le 11/05/2024",
            author: "Apéro du Casco",
            date: "31/05/2024",
        },

    ];

    
  return (
   
    <div className="actualites-main-container"> 
        <div className="actualites-title">
            <h1>Les actualités du Casco</h1>
            <span>Venez participer à nos événements</span>
        </div>
        <div className="actualites-allposts-container">   
            {ArticlesTab.toReversed().map((el, i) => (
                <div key={i} className="actualites-post-container"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        navigate(`/actualites/${el.id}`);
                    }}>
                    <img alt={el.alt} src={el.image} className="actualites-post-image" />
                    <div className="actualites-post-infos">
                        <h2 className="actualites-post-title">{el.title}</h2>
                        <p className="actualites-post-description">{el.description}</p>
                        <div className="actualites-post-details">
                            <p className="actualites-post-author">{el.author}</p>
                            <p className="actualites-post-date">{el.date}</p>
                        </div>
                    </div>
                </div>
            ))}
        
        </div>
    </div>
  );
};

export default Actualites;

import React, { useState, useEffect } from 'react'
import '../styles/slider.css'
import ButtonSlider from '../utils/services/ButtonSlider'
import DataSlider from '../utils/services/DataSlider'

import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa'
import { getSpaceUntilMaxLength } from '@testing-library/user-event/dist/utils'

const Slider = ({ slides }) => {

    const [slideIndex, setSlideIndex] = useState(0)
    const length = slides.length;
    
   /* useEffect(() => {
        gsap.to()
    }) */

    const prevSlide = () => {
        setSlideIndex(slideIndex === 0 ? length - 1 : slideIndex - 1);
    }
    const nextSlide = () => {
        setSlideIndex(slideIndex === length - 1 ? 0 : slideIndex + 1);
    }
    

    if (!Array.isArray(slides) || slides.length <= 0) {
        return null;
    }

    const goToSlide = slideIndex => {
        setSlideIndex(slideIndex)
    }
    

    return (
        <div className="slider-container">
            <div className="slider-container-header"> 
                <h1>
                    Processus 
                </h1>
                <h4> 
                    Notre prise en charge se fait en plusieurs étapes
                </h4>
            </div>
            <div className="slider-wrapper">
                {DataSlider.map((el, i) => (
                    <div key={i} className={i === slideIndex ? "slide-active" : "slide"}>
                        {i === slideIndex && (<img src={el.image} className="slider-image"/>)}
                        {i === slideIndex && (
                        <div className="slider-text">                            
                            <h2>{el.title}</h2>
                            <p>{el.description1}<b>{el.descriptionbold}</b>{el.description2}</p>

                        <div className="contact-info">
                            <p>{el.icon1} 0472/86.83.47 </p>
                            <p>{el.icon2} info@centre-casco.be </p>
                        </div>
                        
                        </div>
                        )}
                        <FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide}/>
                        <FaArrowAltCircleRight className='right-arrow' onClick={nextSlide}/>
                    </div>
                ))}
                
            </div>
                
            <div className="container-dots">
                
                {/*
                <div class="input active">
		    	    <span data-year="Etape 1"></span>
		        </div>
		        <div class="input">
		        	<span data-year="Etape 2"></span>
		        </div>
		        <div class="input">
		        	<span data-year="Etape 3"></span>
		        </div>
                <div class="input">
		        	<span data-year="Etape 4"></span>
                        </div> */}
            
            {/* {Array.from({length: 4}).map((item, index) => (
                <div 
                onClick={() => goToSlide(index)}
                className={slideIndex === index ? "dot active" : "dot"}
                ></div>
            ))}
            */}

                {DataSlider.map((el, i) => (
                    <div 
                    key={i}
                    onClick={() => goToSlide(i)}
                    className={ i === slideIndex ? "input active" : "input"}>
                            <span className="slider-step">
                            {el.bouton}
                            </span>                    
                    </div>
                ))}
            </div>                    
        </div>
    );
};

export default Slider;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InvitationRecto2 from '../assets/apero_casco.png'


const Article2 = () => {
    const navigate = useNavigate();
    
  return (

    <div className="actualites-main-container">
        <h3 className="actualites-page-title"> Nous vous convions à la toute première édition de l’Apéro du CASCO le vendredi 6 octobre.
        </h3>
        <div className="actualites-posts-wrapper">
            <img src={InvitationRecto2}
                  title=""
                  alt=""
                  className="actualites-invit"
                />
            <div className="actualites-invit-description">
                <p>
                 L’occasion pour nous de faire du lien, de rencontrer nos voisins de quartier et d’échanger : tout simplement se réunir !
                 <br/> <br/>
Nous vous attendons donc à partir de 18h dans votre nouveau centre paramédical dans le quartier Uccle-Calevoet.


 
                  <br/> <br/>
                  Nous aurons prévu de quoi boire et grignoter mais toutes participations sont les bienvenus !
                </p>    
            </div>
        </div>
        </div>
  );
};

export default Article2;

import React, { useState } from "react";
import ArticleImage1 from "../assets/MarionAbou.png";
import ArticleImage2 from "../assets/MarionAbou.png";
import ArticleImage3 from "../assets/MarionAbou.png";
import BackgroundImage from "../assets/photo-nature-min.jpg";
import { Link, useNavigate } from "react-router-dom";
import useProgressiveImg from "../utils/services/ImageHook";
import AccessibilityCards from "../components/AccessibilityCards"

const Accessibilite = () => {
    const navigate = useNavigate();
        
    const ArticlesTab = [
        {
            id: "1",
            image: ArticleImage1,
            alt: "image de jose",
            title: "La nouvelle couleur des toilettes.",
            description: 
                    "Apres de nombreux tests de peintures, ce sera noir.",
            author: "Antoine",
            date: "07/08/2022",
        },
        {
            id: "2",
            image: ArticleImage2,
            alt: "pierrick",
            title: "Les stars du cabinet.",
            description: 
                "Nous avons 3 stars : le bleus, le rouge et le vert. L'indigo est sympatique également.",
            author: "Moule",
            date: "01/09/2022",
        },
        {
            id: "3",
            image: ArticleImage3,
            alt: "antoine",
            title: "Le plus beau de tous.",
            description: 
                    "Quels sont donc ces serpents qui sifflent sur vos têtes ?",
            author: "Antoine",
            date: "22/12/2100",
        },
    ];

    
  return (
    <div className="body">
        <div className="header-container">
            <div className="left">
                <div className="inner">
                    <h1>Accessibilité</h1>
                    <p>Le CASCO se construit autour des besoins de tous !</p>
                </div>
            </div>
    {/*        <div>
                <img src={BackgroundImage}
                    title=""
                    alt=""
                    className="image-accessibilite"
                    loading="lazy"
                />
  </div> */}
        </div>
        <div className="accessibility-content">
            <AccessibilityCards/>
            <div className="accessibility-description">
                <p> 
                    Vous accueillir c’est penser au mieux toutes situations d’inconfort, de difficultés ou d’handicap. 
                    Nous espérons donc que chacun se sentira inclus dans cet espace que nous avons voulu le plus convivial possible. 
                    Afin que les moments d’attente vous soient le plus profitables possible, des espaces de travail et de repos sont disponibles. 
                </p>
            </div>
        </div>
    </div>
  );
};

export default Accessibilite;

import '../styles/specialites.css'


const data1 = [
  { seance: "Bilan initial / séance de 30min (5 max)", honoraires: "36,50€", remboursement: "33,50€", ticket: "3€" },
  { seance: "Bilan d'évolution (1 séance)", honoraires: "51,50€", remboursement: "47€", ticket: "4,50€" },
  { seance: "Prise en soin / séance de 30min", honoraires: "30,86€", remboursement: "28,86€", ticket: "2€" },
  { seance: "Prise en soin / séance de 60min", honoraires: "61,98€", remboursement: "57,48€", ticket: "4,50€" },
]
const data2 = [
    { seance: "Bilan initial / séance de 30min (5 max)", honoraires: "43€", remboursement: "29€", ticket: "14€" },
    { seance: "Bilan d'évolution (1 séance)", honoraires: "70€", remboursement: "40,50€", ticket: "29,50€" },
    { seance: "Prise en soin / séance de 30min", honoraires: "35€", remboursement: "25,36€", ticket: "9,64€" },
    { seance: "Prise en soin / séance de 60min", honoraires: "70€", remboursement: "50,98€", ticket: "19,02€" },
  ]
const data3 = [
    { seance: "Bilan initial / séance de 30min (5 max)", honoraires: "43€", remboursement: "36,50€", ticket: "6,50€" },
    { seance: "Bilan d'évolution (1 séance)", honoraires: "70€", remboursement: "51,50€", ticket: "18,50€" },
    { seance: "Prise en soin / séance de 30min", honoraires: "35€", remboursement: "30,86€", ticket: "4,14€" },
    { seance: "Prise en soin / séance de 60min", honoraires: "70€", remboursement: "61,98€", ticket: "8,02€" },
  ]
  
function TableLogopedie() {
  return (
    <div className="specialite-container">
        <div className='specialite-text'>
            <h2>Je soussignée, Marion ABOU, atteste être logopède sous le numéro INAMI : 6-00884-31-801</h2>
            <h2>Voici les honoraires de prise en soin logopédique à partir du 01/01/2023 : </h2>
        </div>
        <div className="table-logopedie">
          <table>
            <caption>
                Patient Omnio {"\n"}Si DMG** en ordre, remboursement total
            </caption>
            <tr>
              <th></th>
              <th>Honoraires</th>
              <th>Remboursement</th>
              <th>Ticket modérateur*</th>
            </tr>
            {data1.map((val, key) => {
              return (
                <tr key={key}>
                  <td>{val.seance}</td>
                  <td>{val.honoraires}</td>
                  <td>{val.remboursement}</td>
                  <td>{val.ticket}</td>
                </tr>
              )
            })}
          </table>
        </div>
        <div className="table-logopedie">
          <table>
            <caption>
                Patient non-Omnio {"\n"}Si DMG** en ordre, remboursement total
            </caption>
            <tr>
              <th></th>
              <th>Honoraires</th>
              <th>Remboursement</th>
              <th>Ticket modérateur*</th>
            </tr>
            {data2.map((val, key) => {
              return (
                <tr key={key}>
                  <td>{val.seance}</td>
                  <td>{val.honoraires}</td>
                  <td>{val.remboursement}</td>
                  <td>{val.ticket}</td>
                </tr>
              )
            })}
          </table>
        </div>
        <div className="table-logopedie">
          <table>
            <caption>
                Patient non-Omnio avec DMG** en ordre
            </caption>
            <tr>
              <th></th>
              <th>Honoraires</th>
              <th>Remboursement</th>
              <th>Ticket modérateur*</th>
            </tr>
            {data3.map((val, key) => {
              return (
                <tr key={key}>
                  <td>{val.seance}</td>
                  <td>{val.honoraires}</td>
                  <td>{val.remboursement}</td>
                  <td>{val.ticket}</td>
                </tr>
              )
            })}
          </table>
        </div>
        <div className="specialite-text">
            <p>
                Attention le remboursement est fait, si et seulement si, l’accord de la mutuelle a été obtenu. En cas
                de non accord mutuelle, l'entièreté du paiement est à charge du patient. Certaines mutuelles
                complémentaires remboursent entre 5 et 10 euros par séance. 
            </p>
            <p>
                * Ticket modérateur = quote-part personnelle : <a id="1" 
                href="https://www.inami.fgov.be/fr/themes/cout-remboursement/par-mutualite/Pages/default.aspx" target="_blank">
                    https://www.inami.fgov.be/fr/themes/cout-remboursement/par-mutualite/Pages/default.aspx</a>
            </p>
            <p>
            ** DMG = Dossier Médical Global : <a id="2" 
                href="https://www.belgium.be/fr/sante/cout_des_soins/dossier_medical_global" target="_blank">
                    https://www.belgium.be/fr/sante/cout_des_soins/dossier_medical_global
                    </a>
            </p>
        </div>
        
    </div>
  );
}
  
export default TableLogopedie;

 
import React from "react";
import ArticleImage3 from "../assets/apero_casco_2.png";


const Article2 = () => {
    
  return (

    <div className="actualites-main-container">
        <h3 className="actualites-page-title"> Venez pour notre apéro du printemps ce 31 mai
        </h3>
        <div className="actualites-posts-wrapper">
            <img src={ArticleImage3}
                  alt="flyer de présentation apéro CASCO"
                  className="actualites-invit"
                  style={{margin: "auto"}}
                />
            <div className="actualites-invit-description">
                <p>
                    Le printemps est enfin arrivé et avec lui viennent les occasions de célébrer la convivialité et le renouveau. 
                    <br/> <br/>
                    C'est pourquoi nous vous convions à notre apéro de printemps au sein de notre centre aramédical le 31 mai prochain !
                    <br/> <br/>
                    Rejoignez-nous à partir de 18h pour un moment de détente et de partage autour de délicieuses bouchées et rafraîchissements.
                    <br/> <br/>
                    Ce sera l'occasion parfaite de se retrouver, d'échanger et de célébrer ensemble l'arrivée de cette belle saison.
                </p>    
            </div>
        </div>
        </div>
  );
};

export default Article2;

import '../styles/specialites.css'

const data = [
    { mutuelle: "Solidaris", limite: "Pour toute personne", montant: "20€", nombre: "8/an" },
    { mutuelle: "Euromut", limite: "Pour toute personne", montant: "10€", nombre: "6/an" },
    { mutuelle: "Partenamut", limite: "Pour toute personne", montant: "10€", nombre: "5/an" },
    { mutuelle: "Mutualité Libérale", limite: "- de 21 ans", montant: "20€", nombre: "12/an" },
    { mutuelle: "Mutualité Chrétienne", limite: "Pour toute personne", montant: "10€", nombre: "5/an" },
    { mutuelle: "Omnimut", limite: "Pour toute personne", montant: "6,20€", nombre: "50" },
    { mutuelle: "Mutualité Neutre du Hainaut", limite: "Pour toute personne", montant: "10€ (20€ bilan)", nombre: "6/an hors bilan" },
  ]
    
  function TablePsy() {
    return (
      <div className="specialite-container">
          <div className='specialite-text'>
              <h2>Titre.............................</h2>
          </div>
          
          <div className="table-logopedie">
            <table>
              <caption>
                  Prise en charge mutuelle
              </caption>
              <tr>
                <th></th>
                <th>Limite</th>
                <th>Montant remboursé par séance</th>
                <th>Nombre de séances couvertes</th>
              </tr>
              {data.map((val, key) => {
                return (
                  <tr key={key}>
                    <td>{val.mutuelle}</td>
                    <td>{val.lmite}</td>
                    <td>{val.montant}</td>
                    <td>{val.nombre}</td>
                  </tr>
                )
              })}
            </table>
          </div>
          <div className="specialite-text">
              <p>
              Les assurances complémentaires permettent une prise en charge plus conséquente de vos séances.
              </p>
          </div>
          
      </div>
    );
  }
    
  export default TablePsy;
  
   
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Access1 } from "../assets/AI/accessibility1.svg";
import { ReactComponent as Access2 } from "../assets/AI/accessibility2.svg";
import { ReactComponent as Access3 } from "../assets/AI/accessibility3.svg";
import { ReactComponent as Access4 } from "../assets/AI/accessibility4.svg";
import { ReactComponent as Access5 } from "../assets/AI/accessibility5.svg";
import { ReactComponent as Access6 } from "../assets/AI/accessibility6.svg";


const AccessibilityCards = ({
  title,
  strongTitle,
  highlightedTitle,
  description,
}) => {
  const navigate = useNavigate();
  const cardsInfosTab = [
    {
      number : "one",
      icon: (
        <Access1
          className="accessibility-card-icon"
          title=""
          alt=""
          
        />
      ),
      title: "Accompagné",
      description:
        "",
    },
    {
      number : "two",
      icon: (
        <Access2
          className="accessibility-card-icon"
          title=" "
          alt=" "
        />
      ),
      title: "Handicap visuel",
      description:
        " ",
    },
    {
      number : "three",
      icon: (
        <Access3
          className="accessibility-card-icon"
          title=" "
          alt=" "
        />
      ),
      title: "handicap auditif",
      description: " ",
    },
    {
      number : "four",
      icon: (
        <Access4
          className="accessibility-card-icon"
          title=" ."
          alt=" "
        />
      ),
      title: "Handicap moteur",
      description:
        " ",
    },
    {
      number : "five",
      icon: (
        <Access5
          className="accessibility-card-icon"
          title=" "
          alt=" "
        />
      ),
      title: "Mobilité réduite",
      description:
        " ",
    },{
      number : "six",
      icon: (
        <Access6
          className="accessibility-card-icon"
          title=" "
          alt=" "
        />
      ),
      title: "Handicap mental",
      description:
        " ",
    },
  ];

  return (
    <div className="accessibility-cards-container">
        <h1>
            Pensé pour tout type de handicap
        </h1>
        <div className="accessibility-cards-wrapper">
            {cardsInfosTab.map((el,i) => (
            <div className="accessibility-cards">   
                <p className="accessibility-card-icon">{el.icon}</p>
                <p className="accessibility-card-title">
                    <h3>{el.title}</h3>
                </p>
            </div>  
              ))}
        </div>
    </div>    
  );
};

export default AccessibilityCards;

import React, { useState } from "react";
import Uka from '../assets/Uka.jpg'
import Uka2 from '../assets/Uka3.JPG'
import '../styles/chien.css'
import { ReactComponent as AutismeIcon } from "../assets/autism.svg";
import { ReactComponent as LearningIcon } from "../assets/learning.svg";
import { ReactComponent as AlzheimerIcon } from "../assets/alzheimer2.svg";
import { ReactComponent as LanguageIcon } from "../assets/language.svg";


const Chien = () => {
  return (
    <div className="chien-container">
      <div className="chien-header-container">
        <h1>
          Thérapie Assistée par l'Animal
        </h1>
        <div className="chien-header">    
          <div className="chien-header-image">
            <img src={Uka2}
              style={{borderRadius: '8px', height: '550px'}}
              title="Image du chien labrador"
              alt="Image du chien labrador"
              className="chien-photo1"
            />
          </div>
          <div className="chien-header-description">
            <p>
              Depuis novembre 2023, nous accueillons UKA, une jeune labrador comme partenaire médiateur. 
              <b> Uka sert de relais entre le thérapeute et le patient.</b> Elle est soit directement en interaction avec le patient soit discrètement 
              présente au cours de la séance selon le besoin et le respect de chacun.
              <br/><br/>L'objectif est de renforcer et de compléter l'action entreprise durant la séance de soin. 
              Nous visons ainsi : <b>une ambiance positive, une prise en soin mieux acceptée, une meilleure estime du patient.</b> La 
              relation devient triangulaire, diminuant ainsi la pression pour le patient.
            </p>
          </div>
        </div>
      </div>
      <div className="chien-container2">
        <h1>
          Accompagnements proposés
        </h1>
        <p>
          Au sein du centre Uka vous soutient dans des accompagnements divers
        </p>
        <div className="chien-cards-wrapper">
          <div className="chien-card-wrapper">
              <AutismeIcon
                className="chien-card1"
                title=""
                alt=""
              />
            <div className="chien-card-text">
              <p>Trouble du spectre et de l'autisme</p>
            </div>
          </div>
          <div className="chien-card-wrapper">
            <LearningIcon
                className="chien-card2"
                title=""
                alt=""
              />
            <div className="chien-card-text">
              <p>Troubles de l’apprentissage</p>
            </div>
          </div>
          <div className="chien-card-wrapper">
            <AlzheimerIcon
                className="chien-card3"
                title=""
                alt=""
              />
            <div className="chien-card-text">
              <p>Maladie d’Alzheimer</p>
            </div>
          </div>
          <div className="chien-card-wrapper">
            <LanguageIcon
                className="chien-card4"
                title=""
                alt=""
              />
            <div className="chien-card-text">
              <p>Divers troubles du langage</p>
            </div>
          </div>
            <br></br><br></br>
        </div>
        <div className="chien-contrindication-wrapper">
          <img
            src={Uka}
            style={{borderRadius: '8px', height: '550px'}}
            title="Image du chien labrador"
            alt="Image du chien labrador"
            className="chien-photo2"> 
          </img>
          <div className="chien-contrindication-description">
            <h4>
              Cependant, la thérapie assistée par l'animal ne convient pas à tout le monde, n'hésitez donc pas à en parler en séance 
              ou au préalable à votre thérapeute. 
              <br></br><br></br>
            </h4>
            <ul>
              Nous serons attentifs aux trois conditions suivantes :
              <li>Ne pas avoir de contre-indication médicale</li>
              <li>Ne pas avoir peur de l'animal</li>
              <li>Etre volontaire pour participer à l'activité avec l'animal</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chien;

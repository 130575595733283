import React, { lazy } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import "./App.css";
import ScrollToTop from "./hooks/ScrollToTop";
import Actua from "./pages/Actualites"
import Article1 from "./pages/Article1"
import Article2 from "./pages/Article2"
import Article3 from "./pages/Article3"
import Access from "./pages/Accessibilite"
import Team from "./pages/Equipe"
import Chien from "./pages/Chien"
import Philosophie from "./pages/Philosophie"
import Logopedie from "./pages/Logopedie"
import Psy from "./pages/Psy"
import { Component } from 'react'
import HttpsRedirect from 'react-https-redirect';
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'G-E6NDXGQKH7'
}
TagManager.initialize(tagManagerArgs)

//console.log(process.env.REACT_APP_API_MAP_KEY)

const queryClient = new QueryClient();

const Navbar = lazy(() => import("./layout/Navbar"));
const Footer = lazy(() => import("./layout/Footer"));
const Actual = lazy(() => import("./pages/Actualites"));
const Home = lazy(() => import("./pages/Home"));
const Accessibilite = lazy(() => import("./pages/Accessibilite"));
const Equipe = lazy(() => import("./pages/Equipe"));

class App extends Component {
  state = {
    data: null
  };

    componentDidMount() {
      this.callBackendAPI()
        .then(res => this.setState({ data: res.express }))
        .catch(err => console.log(err));
    }
      // fetching the GET route from the Express server which matches the GET route from server.js
    callBackendAPI = async () => {
      const response = await fetch('/express_backend');
      const body = await response.json();

      if (response.status !== 200) {
        throw Error(body.message) 
      }
      return body;
    };
    render() {
  return (
    <>
        <QueryClientProvider client={queryClient}>
          <HttpsRedirect>
            <Router>
              <ScrollToTop />
                <Navbar />
                <Routes>
                  <Route 
                  exact path="/" element={<Home />} >
                  </Route>
                  <Route 
                  exact path="/philosophie" element={<Philosophie />} >
                  </Route>
                  <Route 
                  exact path="/logopedie" element={<Logopedie />} >
                  </Route>
                  <Route 
                  exact path="/psychologie" element={<Psy />} >
                  </Route>

                  <Route 
                  exact path="/actualites" element={<Actua />} >
                  </Route>
                  <Route 
                  exact path="/accessibilite" element={<Access />} >
                  </Route>
                  <Route 
                  exact path="/lequipe" element={<Team />} >
                  </Route>
                  <Route 
                  exact path="/mediation-animale" element={<Chien />} >
                  </Route>
                  <Route 
                  exact path="actualites/1" element={<Article1 />} >
                  </Route>
                  <Route 
                  exact path="actualites/2" element={<Article2 />} >
                  </Route>
                  <Route 
                  exact path="actualites/3" element={<Article3 />} >
                  </Route>
                </Routes>
                <Footer />
            </Router>
          </HttpsRedirect>
        </QueryClientProvider>
    </>
  );
              }
}

export default App;